import React from "react"
import { Link } from "react-router-dom"

const Demos = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Link to="/landing">Back</Link>
      <span style={{ marginTop: "1em" }}>Demo Videos</span>
      <iframe
        width="540"
        height="300"
        style={{ margin: "2em", borderRadius: "1em" }}
        src="https://www.loom.com/embed/7e0c64a2faa2415b889596fb14c4e06c"
        frameBorder="0"
        allowFullScreen
      ></iframe>
      <iframe
        width="540"
        height="300"
        style={{ margin: "2em", borderRadius: "1em" }}
        src="https://www.loom.com/embed/53b5dd0a8e554ae89de616bb6b6a1a5a"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default Demos
