import { makeArr } from "./Components/Search/Feed/FeedItem/UzmaStack"
import { TextPost } from "./PostContext"

export function determineWhichEmbeddingToUse(text: string, isFocus?: boolean): "doc" | "query" {
  //default method
  if (typeof isFocus !== "undefined") return isFocus ? "query" : "doc"

  if (text.includes("?") && text.length < 200) return "query"
  else if (text.length < 100) return "query"
  else return "doc"
}
export function getEmbeddingFromPost(post: TextPost, isFocus?: boolean): number[] {
  const { openaiEmbedding, openaiQueryEmbedding } = post
  if (!openaiEmbedding || !openaiQueryEmbedding) {
    return (openaiEmbedding ?? openaiQueryEmbedding) as number[]
  } else {
    return determineWhichEmbeddingToUse(post.text, isFocus) === "doc"
      ? openaiEmbedding
      : openaiQueryEmbedding
  }
}

export function averageTheseEmbeddings(embeddings: number[][]) {
  //assert
  if (!embeddings) return undefined
  if (embeddings) console.assert(embeddings[0].length == embeddings[embeddings.length - 1].length)
  const startingVector = embeddings[0]
  return embeddings
    .slice(1)
    .reduce((averageSoFar: number[], nextVector: number[], index: number) => {
      return averageSoFar.map(
        (val: number, itemIndex: number) =>
          ((index + 1) / (index + 2)) * val + (1 / (index + 2)) * nextVector[itemIndex]
      )
    }, startingVector)
}
