import React, { Dispatch, SetStateAction, useContext, useMemo } from "react"
import { useParams } from "react-router-dom"
import { Descendant, Node } from "slate"
import PostContext, { TextPost } from "../../../PostContext"
import "./Feed.css"
import FeedInterface from "./FeedInterface"
import { timestamp } from "./FilteringLogic"

const SearchFeed = ({
  query,
  setQuery,
}: {
  query: string
  setQuery: Dispatch<SetStateAction<string>>
}) => {
  const { thoughtId, placeId } = useParams()
  const { posts, semanticallyRelatedPosts } = useContext(PostContext)
  const postArr: TextPost[] = useMemo(() => {
    const arr = Object.values(posts)
      .filter((e) => typeof e.text === "string" && e.timestamp)
      .sort((b, a) => timestamp(a) - timestamp(b))
    // console.log({
    //   postArr: arr.map((e) => [e.text?.slice(0, 10), timestamp(e) - timestamp(arr[0])]),
    // })
    return arr
  }, [posts])
  //where do the post arr get filtered?

  return (
    <FeedInterface
      key={(thoughtId ?? placeId) + "-feed"}
      {...{
        query,
        setQuery,
        thoughts: semanticallyRelatedPosts ?? postArr,
        enableWe: false,
        meVsWe: true,
      }}
    />
  )
}

export const getTextFromChildren = (children: Descendant[]) => {
  return children.reduce((text: string, nextChild: Descendant) => {
    return text + " " + Node.string(nextChild)
  }, "")
}

export default SearchFeed
