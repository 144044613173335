// @flow strict

import * as React from "react"
import { EdgeInfo, EdgeKind } from "../../../../PostContext"
import FeedItem, { EdgeMap } from "./FeedItem"
import "./LinkedThoughts.css"
import SmartFeedItem from "./SmartFeedItem"
export type FeedItemType = "linked" | "principal" | "suggested"
function LinkedThoughts({
  links,
  lineage,
  above,
}: {
  links: [string, EdgeInfo][]
  lineage: string[]
  above: boolean
}) {
  return (
    <div className={"linked-thoughts" + (above ? " above" : " underneath")}>
      {links
        .filter((e) => !(lineage ?? []).includes(e[0]))
        .map(([id, edgeinfo]) => (
          <SmartFeedItem
            breadcrumb={above}
            id={id}
            type={"linked"}
            lineage={lineage}
            key={lineage.concat("-") + id}
            isReply={edgeinfo.edgeKind === EdgeKind.REPLY}
          />
        ))}
    </div>
  )
}

export default LinkedThoughts
