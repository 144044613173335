import { Channel, Client, GatewayIntentBits, Message } from "discord.js"
import { REST } from "@discordjs/rest"
import { barePostInfo } from "./VisionCommitteePosts"
import { channel } from "diagnostics_channel"

const VC_AuthorMap = {
  "426610725990039565": "Len",
  "324270902751526913": "moorhys",
  "984125939929710652": "Nadine",
  "997267197355364425": "Adam Welter",
  "923310883453681734": "Davey",
  "826570714458357792": "annahildebrand",
  "794410058141138954": "anniepants11",
  "984895871541989436": "Esther Toso",
  "985090338710487060": "Arisa",
  "861778554852081704": "bobissett",
  "596330805144322059": "Bodo",
  "849706987390500865": "Darlene",
  "987359162520457236": "hillcreststudio",
  "964352078552113194": "ianrendersblenders",
  "984440586394279936": "josweetland",
  "834493559322181673": "Julie M",
  "984125642289348658": "Kezia",
  "880678190727700491": "Linda Wu",
  "892811795193032744": "Mary-Ellen",
  "907925028497154049": "nathan_day",
  "399713205267726348": "OhanaLife",
  "287720969483583489": "Serj Hunt",
  "690734830198980679": "Stacey",
  "894575386640416799": "suet284",
  "715869025342586931": "Svet",
  "901841823859888198": "Svet Georgiev",
  "756564243385679923": "Tiffany Welter",
  "978477677289832468": "Tinkamar",
  "984124202565120031": "Torsten",
  "820764613456166962": "Vibrant Sun",
  "1002400335677104149": "Whatsup Doc",
  "827172863437176832": "陳爸Timさん",
}
export const getPosts = (): barePostInfo[] => {
  //get from jsons
  const jsonsByChannel = {
    "general-chat": require("./jsons-july29th/chat/generalChat.json"),
    announcements: require("./jsons-july29th/chat/announcements.json"),
    educators: require("./jsons-july29th/chat/educators.json"),
    "parent-intros": require("./jsons-july29th/chat/parentIntros.json"),
    brainstorming: require("./jsons-july29th/workingGroups/brainstorming.json"),
    "coach-offering": require("./jsons-july29th/workingGroups/coachOffering.json"),
    "parent-community-experience": require("./jsons-july29th/workingGroups/parentCommunityExperience.json"),
    "student-discord-reboot": require("./jsons-july29th/workingGroups/studentDiscordReboot.json"),
  }
  const result: barePostInfo[] = []
  Object.entries(jsonsByChannel).forEach(([channelName, channelOfPosts]: [string, any[]]) => {
    channelOfPosts.forEach((message: Message) => {
      result.push({
        //@ts-ignore
        name: message.authorId in VC_AuthorMap ? VC_AuthorMap[message.authorId] : "(discord)", // in VC_AuthorMap ? "#general-chat (discord)",
        text: message.cleanContent,
        timestamp: message.createdTimestamp,
        channel: channelName,
      })
    })
  })
  return result.filter((e) => e.text.length > 0 && e.text !== " ")
}
