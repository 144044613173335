import { child, getDatabase, onValue, ref } from "firebase/database"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { TextPost } from "../../../../PostContext"
import FeedItem from "./FeedItem"
import { FeedItemType } from "./LinkedThoughts"

const SmartFeedItem = ({
  id,
  type,
  lineage,
  breadcrumb,
  isReply,
}: {
  id: string
  type: FeedItemType
  lineage?: string[]
  breadcrumb: boolean
  isReply: boolean
}) => {
  const { placeId } = useParams()
  const [post, setPost] = useState<TextPost | undefined>()
  useEffect(() => {
    const db = getDatabase()
    const domainRef = ref(db, placeId ?? "forum") //nodes are posts
    const postRef = child(domainRef, "/nodes/" + id) //nodes are posts
    onValue(postRef, (snapshot) => {
      if (snapshot.exists()) {
        const value = snapshot.val()
        setPost(value)
      }
    })
  }, [id])
  return post && post.slateValue ? (
    <FeedItem {...{ post, lineage, query: "", originStory: type, breadcrumb, isReply }} />
  ) : (
    <></>
  )
}

export default SmartFeedItem
