import { setServers } from "dns"
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import PostContext from "../../PostContext"
import Bar from "./Bar/Bar"
import SearchFeed from "./Feed/Feed"
import "./Search.css"

const Search = ({
  query,
  setQuery,
  open,
  setOpen,
  setSearchPanelOpen,
}: {
  query: string
  setQuery: any
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  setSearchPanelOpen: Dispatch<SetStateAction<boolean>>
}) => {
  useEffect(() => {
    if (query) {
      setOpen(true)
      setSearchPanelOpen(true)
    }
  }, [query])
  return (
    <div className="panel column secondary-panel open">
      {open ? <SearchFeed query={query} setQuery={setQuery} /> : <></>}
    </div>
  )
}

export default Search
