import React, { useContext, useEffect, useMemo, useState } from "react"
import Create from "../Components/Create/Create"
import Search from "../Components/Search/Search"
import { samplePostMap } from "../database"
import PostContext, { PostMap, TextPost, WordMap } from "../PostContext"

import {
  getDatabase,
  ref,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  onValue,
} from "firebase/database"

import FirebaseWriter, {
  getOpenAiQueryEmbedding,
  getOpenAiTextEmbedding,
  makePost,
} from "../FirebaseWriter"
import { withHistory } from "slate-history"
import { withReact } from "slate-react"
import { createEditor, Node } from "slate"
//@ts-ignore
import similarity from "compute-cosine-similarity"
import { auth, provider, wordMatching } from "../App"
import { Navigate, useParams } from "react-router-dom"
import AuthContext from "../AuthContext"
import { getRedirectResult, GoogleAuthProvider, signInWithRedirect, User } from "firebase/auth"
import DomainLabel from "./DomainLabel"
import { getPosts } from "../ManualTransfers/DiscordBot"
import { getEmbeddingFromPost } from "../embeddingLogic"
import { getPlaceholder } from "./EditorContainer"

export let domainId = "forum"

export let backendWriter: FirebaseWriter
export let usernamex = localStorage.getItem("place-username") ?? ""

//the exported version will be t

export const runOnboardingMessages = () => {
  while (!usernamex) {
    usernamex = window.prompt("What on earth should we call you (first name)?") ?? ""
  }
  localStorage.setItem("place-username", usernamex)
  backendWriter.setName(usernamex)
  window.alert("Really good to have you here, " + usernamex + ".")
}

function FullAppContainer() {
  const [posts, setPosts] = useState<PostMap>({})
  const [dictionary, setDictionary] = useState<WordMap>({})
  const [firstTime, setFirstTime] = useState(false)
  const [loadingPosts, setLoadingPosts] = useState(true)

  //domainID
  const { placeId, thoughtId } = useParams()
  const { person } = useContext(AuthContext)

  //update posts from firebase
  useEffect(() => {
    //if placeId, set domain id
    if (placeId) domainId = placeId

    const db = getDatabase()
    const domainRef = ref(db, domainId) //nodes are posts
    const postsRef = ref(db, domainId + "/nodes") //nodes are posts
    onValue(postsRef, (snapshot) => {
      if (snapshot.exists()) {
        const value = snapshot.val()
        setPosts(value)
      }
      setLoadingPosts(false)
    })
    const wordsRef = ref(db, domainId + "/dictionary")
    onValue(wordsRef, (snapshot) => {
      if (snapshot.exists()) {
        const value = snapshot.val()
        setDictionary(value)
      }
    })

    backendWriter = new FirebaseWriter(
      domainRef,
      person?.uid ?? "",
      person?.email ?? "",
      usernamex ?? "", // won't be defined yet
      getPlaceholder(placeId)
    )
  }, [])

  useEffect(() => {
    if (backendWriter && person?.uid) backendWriter.setPersonId(person?.uid)
  }, [person])
  const [editor, setEditor] = useState(() => withHistory(withReact(createEditor())))
  const resetEditor = () => setEditor(withHistory(withReact(createEditor())))
  const [personalFilter, setPersonalFilter] = useState(true)
  const [relevanceFilter, setRelevanceFilter] = useState(false)
  const [semanticallyRelatedPosts, setSemanticallyRelatedPosts] = useState<TextPost[] | undefined>(
    []
  )
  //this stores the result of the most recent semantic search query
  useEffect(() => {
    if (relevanceFilter && person) {
      //make sure I can get editor text inside location
      const text = Node.string(editor)

      const embeddingPromise = getOpenAiQueryEmbedding(text, person.uid ?? usernamex)

      embeddingPromise.then((embedding) => {
        const vector = embedding.data.data ? embedding.data.data[0].embedding : undefined
        const relatedPosts = findRelatedPosts(posts, vector)
        setSemanticallyRelatedPosts(relatedPosts)
      })
    } else setSemanticallyRelatedPosts(undefined)

    //set me on when there's no principal thought, me off when there is
    if (thoughtId) setPersonalFilter(!thoughtId)

    //get rid of postarr dependency eventually
  }, [relevanceFilter, thoughtId])

  const todaysPrompt = useMemo(() => {
    //set in firebase
    const prompt = getPlaceholder(placeId)
    return prompt
  }, [placeId])
  return (
    <PostContext.Provider
      value={{
        posts,
        setPosts,
        dictionary,
        setDictionary,
        personalFilter,
        setPersonalFilter,
        relevanceFilter,
        setRelevanceFilter,
        semanticallyRelatedPosts,
        setSemanticallyRelatedPosts,
        loadingPosts,
        setLoadingPosts,
        todaysPrompt,
      }}
    >
      <div className={"App column" + (wordMatching ? " wordMatching" : "")}>
        {placeId && placeId !== "forum" ? <DomainLabel domain={placeId ?? ""} /> : <></>}

        <Create editor={editor} resetEditor={resetEditor} />
      </div>
    </PostContext.Provider>
  )
}

export default FullAppContainer

export function findRelatedPosts(posts: any, vector: number[]) {
  //posts should be up to date enough
  const postArr: TextPost[] = Object.values(posts).filter(
    (e: any) => e?.slateValue?.length > 0
  ) as TextPost[]
  type sortItem = [TextPost, number]
  const similarityArr: sortItem[] = postArr.map((post) => {
    let sim = 0
    const embeddingToUse = post.openaiEmbedding //getEmbeddingFromPost(post)
    //find distance between post vector and vector vector
    //if post vector isn't defined... just returned 0
    if (!embeddingToUse) {
    } else if (embeddingToUse.length == vector.length) {
      //otherwise, measure distance
      sim = similarity(embeddingToUse, vector)
    }
    return [post, sim]
  })
  const sorted = similarityArr.sort((a: sortItem, b: sortItem) => b[1] - a[1])
  const truncated = sorted.map((e) => e[0])
  // .filter((e) => e[1] > 0.23)
  // const truncated2 = truncated.length > 0 ? truncated : sorted.slice(0, 10)
  // const sortedPosts = truncated2.slice(0, 30).map((e) => e[0])

  return truncated
}
