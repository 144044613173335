// @flow strict

import { isEmpty } from "@firebase/util"
import * as React from "react"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { createEditor, Descendant, Editor, Node, Transforms } from "slate"
import { withHistory } from "slate-history"
import { Slate, withReact, Editable, ReactEditor } from "slate-react"
import AuthContext from "../../../../../AuthContext"
import PostContext, { EdgeKind } from "../../../../../PostContext"
import { initialValue } from "../../../../EditorContainer"
import { backendWriter, runOnboardingMessages, usernamex } from "../../../../FullAppContainer"
import "./Reply.css"

function Reply({ to }: { to: string }) {
  const { thoughtId, placeId } = useParams()
  const { person } = React.useContext(AuthContext)

  const [value, setValue] = React.useState(initialValue)
  const [editor, setEditor] = React.useState(() => withHistory(withReact(createEditor())))
  const resetEditor = () => {
    Transforms.delete(editor, {
      at: {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
      },
    })
    // setEditor(withHistory(withReact(createEditor())))
  }
  const [empty, setEmpty] = React.useState(true)
  const onChange = (children: Descendant[]) => {
    if (children.length > 0 && Node.string(children[0]).length > 0) {
      setEmpty(false)
    } else setEmpty(true)
  }
  React.useEffect(() => {
    ReactEditor.focus(editor)
    Transforms.select(editor, Editor.end(editor, []))
  }, [])
  const replyBackend = (e: any) => {
    //if editor isn't empty, submit a reply
    //thought id should always be defined
    if (!empty && thoughtId && !e.shiftKey) {
      const string = Node.string(editor)
      if (!backendWriter.personId) {
        e.preventDefault()
        backendWriter.ensureSignedIn(backendWriter.personId?.length > 0, runOnboardingMessages)
        return
      }
      const addPostResult = backendWriter.addPost(editor.children, string)
      const { postWithId, addPostPromise } = addPostResult
      addPostPromise?.then((e: any) => {
        //then link it up
        //what's the direciton
        if (postWithId?.id) {
          backendWriter.addLink(thoughtId, postWithId?.id, {
            sourceId: thoughtId,
            authorId: person?.uid ?? usernamex,
            edgeKind: EdgeKind.REPLY,
            timestamp: Date.now(),
          })
        }
      })
      e.preventDefault()
      resetEditor()
      setEmpty(true)
    }
  }
  const navigate = useNavigate()
  const handleKeyDown: React.KeyboardEventHandler = (e) => {
    switch (e.key.toLocaleLowerCase()) {
      case "enter":
        e.preventDefault()

        if (!empty) {
          replyBackend(e)
        } else {
          navigate(`${placeId ? "/p/" + placeId : ""}/`)
        }
        break
      // case "tab":
    }
  }

  return (
    <div className="reply" style={{ display: "flex", flexDirection: "row" }}>
      <Slate {...{ editor, value, onChange }}>
        {/* <div className="reply-row"> */}
        <Editable
          style={{ width: "100%" }}
          placeholder={"reply..."}
          onKeyDown={handleKeyDown}
          autoFocus={true}
        />
      </Slate>
      {!empty ? (
        <div
          className="simple-button"
          style={{ fontSize: ".8em", alignSelf: "top", margin: 0, marginTop: "-.3em" }}
          onClick={replyBackend}
        >
          {"↑"}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default Reply
