import moment from "moment"
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { Descendant, Node } from "slate"
import { person } from "../../../App"
import AuthContext from "../../../AuthContext"
import { cleanPhrase, tokenizeSentence } from "../../../FirebaseWriter"
import { getReplyKeys } from "../../../linkLogic"
import PostContext, { EdgeKind, PostMap, TextPost } from "../../../PostContext"
import SuggestedThought from "../../Detail/SuggestedThought"
import { backendWriter, usernamex } from "../../FullAppContainer"
import "./Feed.css"
import FeedItem from "./FeedItem/FeedItem"
import { postIsFilterableReply, timestamp } from "./FilteringLogic"

//only used now for the personal page
const filterClusterPosts = (arr: TextPost[], posts: PostMap, personalFilter?: boolean) => {
  //sort it properly

  //filter it again by links
  let postIdsSoFar: { [id: string]: boolean } = {}
  const filteredMore = arr.filter((post) => {
    //How to tell if it is a reply?
    const isReply = postIsFilterableReply(post, backendWriter?.personId, posts, personalFilter)
    //If it's been used already, or its a reply, filter it out
    if ((false && personalFilter && post.id in postIdsSoFar) || isReply) return false
    else {
      //return true and add the relevant
      //add the relevant ids
      postIdsSoFar[post.id] = true
      //only loop through replies for now, until expansion includes pinned posts again
      Object.keys(post.links ?? {}).forEach((key) => {
        postIdsSoFar[key] = true
      })
      // getReplyKeys(post.links).forEach((key) => {
      //   postIdsSoFar[key] = true
      // })
      //then add this one
      return true
    }
  })
  return filteredMore
}

const FeedInterface = ({
  query,
  setQuery,
  thoughts,
  relevantVsRecent,
  setShowRelevant,
  showRelevant,
  meVsWe,
  noMargin: noMargins,
  inDetailView,
}: {
  query?: string
  setQuery?: Dispatch<SetStateAction<string>>
  thoughts: TextPost[]
  relevantVsRecent?: boolean
  showRelevant?: boolean
  setShowRelevant?: Dispatch<SetStateAction<boolean>>
  meVsWe?: true
  noMargin?: true
  inDetailView?: true
}) => {
  const { setPersonalFilter, personalFilter, posts } = useContext(PostContext)
  const { person } = useContext(AuthContext)
  const { thoughtId } = useParams()

  const postArr: TextPost[] = thoughts
  const focusedThought = thoughtId ? posts[thoughtId] : undefined

  const filteredPosts = useMemo(() => {
    //sort it properly
    const arrToUse: TextPost[] = postArr.filter((e) => e.timestamp)
    //filter it again by links
    return arrToUse

    // filterClusterPosts(arrToUse)
  }, [query, postArr])

  const myOwnPosts = useMemo(() => {
    //janky old way
    return filteredPosts.filter(
      (post) => backendWriter?.personId && backendWriter.personId === post.authorId
    )
  }, [filteredPosts, personalFilter])
  const everyoneElsesPosts = useMemo(() => {
    return filteredPosts.filter((post) => {
      return post.authorId !== backendWriter?.personId
    })
  }, [filteredPosts, personalFilter])
  // const slicedWe = filteredPosts.slice(0, 10) /// just for demoing

  //when query changes, flip to global / personal if there are none in personal
  //no need for this yet
  const postsToUse = personalFilter ? myOwnPosts : filteredPosts
  //only filter out posts on home page
  //better will be to still filter cluster but to take cues from recency
  const clusteredPosts = !thoughtId
    ? filterClusterPosts(postsToUse, posts, personalFilter)
    : postsToUse
  const tinder = inDetailView
  const selectedPost = clusteredPosts[0]
  const toggleDisabled = true
  return (
    <div className={"search-feed" + (noMargins ? " noMargins" : "")}>
      {relevantVsRecent || meVsWe ? (
        <div className="toggles">
          {relevantVsRecent ? (
            <div
              className="simple-button toggle"
              style={{ fontStyle: "normal", textAlign: "center" }}
              onClick={() => {
                if (setShowRelevant) setShowRelevant((prev) => !prev)
              }}
            >
              <span className={showRelevant ? "selected-option" : ""}>related</span> |{" "}
              <span className={!showRelevant ? "selected-option" : ""}>recent</span>{" "}
            </div>
          ) : (
            <></>
          )}
          {!meVsWe ? (
            <></>
          ) : thoughtId || true ? (
            <div
              id="meVsWe"
              className="toggle"
              style={{ fontStyle: "normal", textAlign: "center" }}
              onClick={() => {
                if (toggleDisabled && person?.email !== "davey@plexusnotes.com")
                  window.alert(
                    "In Plexus, there's no feed. The way to find other people's thoughts is by sharing your own. It's weird, but ultimately more personal. \n\nTo see what we mean, share something that's on your mind!"
                  )
                else setPersonalFilter((prev) => !prev)
              }}
            >
              <span className={personalFilter ? "selected-option" : ""}>
                me ({myOwnPosts.length})
              </span>{" "}
              |{" "}
              <span
                className={
                  (toggleDisabled ? "disabled " : "") + (!personalFilter ? " selected-option" : "")
                }
              >
                we ({filteredPosts.length})
              </span>{" "}
            </div>
          ) : (
            <div
              className="simple-button toggle"
              style={{ fontWeight: "bold", marginBottom: "-1em" }}
            >
              train of thought ({myOwnPosts.length}){/* train of thought ({myOwnPosts.length}) */}
            </div>
            // <></>
          )}
        </div>
      ) : (
        <></>
      )}

      {!tinder ? (
        true ? (
          clusteredPosts.length > 0 ? (
            clusteredPosts
              .slice(0, 80)
              .map((e, i) =>
                e.slateValue ? (
                  <FeedItem key={e.id + usernamex} post={e} originStory="linked" />
                ) : (
                  <></>
                )
              )
          ) : (
            <></>
          )
        ) : (
          <span
            style={{
              display: "flex",
              fontStyle: "italic",
              justifyContent: "center",
              color: "gray",
              // alignSelf: "center",
              padding: "1em",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <span>to see peers' thoughts, first say what's on your mind!</span>
          </span>
        )
      ) : selectedPost ? (
        <SuggestedThought post={selectedPost} focusedThought={focusedThought} />
      ) : (
        // <FeedItem key={selectedPost.id + usernamex} post={selectedPost} originStory="suggested" />
        <span style={{ color: "gray", alignSelf: "center" }}>loading suggested thought...</span>
      )}
    </div>
  )
}

export const getTextFromChildren = (children: Descendant[]) => {
  return children.reduce((text: string, nextChild: Descendant) => {
    return text + " " + Node.string(nextChild)
  }, "")
}

export default FeedInterface
function sortArr(comparator: (a: TextPost, b: TextPost) => boolean, inputArr: TextPost[]) {
  let len = inputArr.length
  for (let i = 0; i < len; i++) {
    for (let j = 0; j < len - i - 1; j++) {
      if (comparator(inputArr[j], inputArr[j + 1])) {
        let tmp = inputArr[j]
        inputArr[j] = inputArr[j + 1]
        inputArr[j + 1] = tmp
      }
    }
  }
  return inputArr
}
