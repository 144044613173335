import { EdgeInfo, EdgeKind, PostMap, TextPost } from "../../../PostContext"

export const postIsFilterableReply = (
  post: TextPost,
  currentAuthorId: string,
  posts: PostMap,
  personalFilter?: boolean
) => {
  const linkArr = Object.entries(post.links ?? {})
  // if (post.id === "-N8_YKukC7WZneLS0w1Y") debugger
  const parentsWithPrecedent = linkArr.filter(
    ([key, link]: [key: string, link: EdgeInfo]) => {
      //author of parent
      const sourcePost = posts[link.sourceId]
      const sourceAuthor = sourcePost?.authorId
      return (
        link.edgeKind === EdgeKind.REPLY && link.sourceId !== post.id
        //only do this in the personal filter case.
        //might want to reenable this once we can find the representative thought from someone else and pin it to the personal feed.
        // (!personalFilter || currentAuthorId !== post.authorId || post.authorId === sourceAuthor)

        // &&
        // sourceAuthor === post.authorId
      )
    }
    // &&
    // (!personalFilter || //if the personal filter is on, then be more lenient about replies
    //   (currentAuthorId && currentAuthorId === post.authorId))
  )
  return parentsWithPrecedent.length > 0
}
export const timestamp = (post: TextPost) => {
  return post.timestamp ?? 0 //post.receivedLinkTimestamp ??
}
