import { User } from "firebase/auth"
import React, { Dispatch, SetStateAction } from "react"
import { Descendant } from "slate"

let AuthContext = React.createContext<{
  personName?: string
  setPersonName: Dispatch<SetStateAction<string | undefined>>
  person?: User
  setPerson: Dispatch<SetStateAction<User | undefined>>
}>({ setPerson: () => undefined, setPersonName: () => undefined })
export default AuthContext
