// const sgMail = require("@sendgrid/mail")
import emailjs from "@emailjs/browser"
export default function sendThanksEmail(
  to_name: string = "anon",
  from_name: string = "anon",
  context_thought: string = "text hidden",
  collected_thought: string = "text hidden",
  reply_to: string = "davey@plexusnotes.com"
) {
  emailjs.send(
    "default_service",
    "template_w1432nr",
    { to_name, from_name, collected_thought, context_thought, reply_to },
    "VFXAIIMaqEenjBrC9"
  )

  //twilio
  //   sgMail.setApiKey(process.env.SENDGRID_API_KEY)
  //   const msg = {
  //     to: "davey@plexusnotes.com", // Change to your recipient
  //     from: "app@plexus.earth", // Change to your verified sender
  //     subject: "Sending with SendGrid is Fun",
  //     text: "and easy to do anywhere, even with Node.js",
  //     html: "<strong>and easy to do anywhere, even with Node.js</strong>",
  //   }
  //   sgMail
  //     .send(msg)
  //     .then(() => {
  //       console.log("Email sent")
  //     })
  //     .catch((error: any) => {
  //       console.error(error)
  //     })
}
