import React from "react"

const DomainLabel = ({ domain }: { domain: string }) => {
  return (
    <div
      style={{
        padding: ".4em",
        color: "rgb(180,180,180)",
        fontWeight: "bold",
        background: "rgb(230,230,230)",
        cursor: "pointer",
        textAlign: "center",
      }}
      title={domain + " planet"}
      onClick={(e) => {
        switch (domain) {
          case "park":
            window.alert(
              "This is an online forum for parks in NYC, to help strangers find meaningful connections and see what others are thinking about.\n\nMy name is Davey Morse! I previously studied AI at Williams College, worked at Apple, and now am creating online experiments to help people make meaningful connections with each other."
            )
            break
          case "cityasaschool":
          case "visioncommittee":
            window.alert(
              "This is a shared brain for Vision Community, meant to help members connect through shared ideas and concerns. This particular shared brain was organized by Serj Hunt. The platform is built by Davey Morse and the Plexus team, a public benefit corporation aiming to build more meaningful online communities. Don't hesitate to reach out to Davey if you have any thoughts or feedback (davey@plexusnotes.com)."
            )
            break

          case "growingtogether":
            window.alert(
              "The purpose of the Growing Together shared brain: foster positive reflection in mental health communities and encourage insightful discussion/engagement on overcoming obstacles!"
            )
        }
      }}
    >
      p/{domain === "forum" ? "everyone" : domain}{" "}
    </div>
  )
}

export default DomainLabel
