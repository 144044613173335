import React, { useContext, useEffect, useState } from "react"
import FullAppContainer, { runOnboardingMessages, usernamex } from "./Components/FullAppContainer"
import "./App.css"
import { BrowserRouter, Navigate, PathRouteProps, Route, Routes, useParams } from "react-router-dom"
import LandingPage from "./Components/LandingPage/LandingPage"
import AuthContext from "./AuthContext"
// Initialize Firebase within the app
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import firebaseConfig from "./FirebaseConfig"
import { signInWithRedirect } from "firebase/auth"

import {
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  onAuthStateChanged,
  User,
} from "firebase/auth"
import PrivateRoute from "./Components/PrivateRoute"
import Demos from "./Components/LandingPage/Demos"
import DomainDetails from "./DomainDetails"
import sendThanksEmail from "./SendEmail"

export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const auth = getAuth()
export const provider = new GoogleAuthProvider()
export let person: User

export let wordMatching: boolean = false

function App() {
  const [person, setPerson] = useState<User>()
  const [personName, setPersonName] = useState<string>()
  const { thoughtId, placeId } = useParams()

  const [firstLoaded, setFirstLoaded] = useState(false)
  //authentication callback
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("auth state change result", Date.now())

        console.log({ user })
        setPerson(user)

        if (!usernamex && !thoughtId) {
          const domainMessages = placeId ? DomainDetails[placeId] : undefined
          runOnboardingMessages()
        }
      }
    })

    // getRedirectResult(auth)
    //   .then((result) => {
    //     if (result) {
    //       console.log("redirect result", Date.now())
    //       // This gives you a Google Access Token. You can use it to access Google APIs.
    //       const credential = GoogleAuthProvider.credentialFromResult(result)
    //       const token = credential?.accessToken
    //       // The signed-in user info.
    //       setPerson(result.user)
    //     }
    //   })
    //   .catch((error) => {
    //     console.warn(error)
    //     // Handle Errors here.
    //     const errorCode = error.code
    //     const errorMessage = error.message
    //     // The email of the user's account used.
    //     const email = error.customData.email
    //     // The AuthCredential type that was used.
    //     const credential = GoogleAuthProvider.credentialFromError(error)
    //     // ...
    //   })
  }, [])

  return (
    <AuthContext.Provider value={{ person, setPerson, personName, setPersonName }}>
      <BrowserRouter>
        <Routes>
          <Route path="/p" element={<PrivateRoute {...{ person, setPerson }} />}>
            <Route path=":placeId" element={<PrivateRoute {...{ person, setPerson }} />}>
              <Route path=":thoughtId" element={<PrivateRoute {...{ person, setPerson }} />} />
            </Route>
          </Route>
          {/* <Route */}
          <Route path="/" element={true ? <LandingPage /> : <Navigate to="/p/render" />} />
          <Route path="/landing" element={<LandingPage />} />
          <Route path="/demos" element={<Demos />} />
        </Routes>
      </BrowserRouter>
    </AuthContext.Provider>
  )
}

export default App
