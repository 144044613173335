import { signInWithRedirect, User } from "firebase/auth"
import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import { auth, provider } from "../App"
import DomainLabel from "./DomainLabel"
import FullAppContainer, { domainId } from "./FullAppContainer"
import "./PrivateRoute.css"

const PrivateRoute = ({
  person,
  setPerson,
}: {
  person?: User
  setPerson?: Dispatch<SetStateAction<User | undefined>>
}) => {
  const { placeId, thoughtId } = useParams()
  const text = useMemo(() => {
    switch (placeId) {
      case "friends":
        return (
          <>
            <strong>p/friends</strong> a place for friends and family of Davey and Uzma to share and
            connect through life reflections. You're some of the first people to give Plexus a whirl
            - we hope it proves to be a meaningful pocket of the internet for each of you.
          </>
        )
      case "visioncommittee":
        return (
          <>
            <strong>/p/visioncommittee</strong> is a shared brain for parents and educators focused
            on innovation in homeschooling.
          </>
        )
      case undefined:
      case "forum":
        return (
          <>
            <strong>/p</strong> is the broadest shared brain in Plexus, meant for people to connect
            with each other through a diversity of interests and topics. To start, it houses a lot
            of meta-notes authored by Davey and Uzma about Earth itself. Feel free to join, and
            contribute your own secret sauce.
          </>
        )
    }
  }, [placeId])
  return person || thoughtId ? (
    <FullAppContainer />
  ) : (
    <div className="login-page">
      <span style={{ display: "inline-flex", lineHeight: "2em" }}>p/{placeId}</span>
      {true ? (
        <span
          style={{
            fontSize: ".7em",
            textAlign: "center",
            fontWeight: "normal",
            maxWidth: "500px",
            margin: "1em",
            lineHeight: "1.5em",
          }}
        >
          {text}
        </span>
      ) : (
        <></>
      )}
      <div className="login-button" onClick={() => signInWithRedirect(auth, provider)}>
        get started
      </div>

      <a
        style={{
          color: "gray",
          fontSize: ".6em",
          fontWeight: "normal",
          fontStyle: "italic",
          textDecoration: "none",
        }}
        href=" https://docs.google.com/document/d/1JvcOJpZJTIXtUfVmN3PVEtYG4POcKjNjVePKJC8WpW8/edit?usp=sharing"
      >
        privacy policy
      </a>
      {/* <span
        style={{
          fontSize: ".7em",
          margin: "1em",
          fontWeight: "normal",
          textAlign: "center",
          lineHeight: "1.4em",
          display: "inline-flex",
        }}
      >
        {!placeId ? (
          <></>
        ) : placeId === "park" ? (
          "Welcome to local twitter for the park!"
        ) : ["cityasaschool", "visioncommittee"].includes(placeId) ? (
          <span>a knowledge network for the Vision Committee </span>
        ) : (
          ""
        )}
      </span> */}
      <br></br>
      <br></br>
    </div>
  )
}

export default PrivateRoute
