import {
  child,
  Database,
  DatabaseReference,
  getDatabase,
  push,
  ref,
  remove,
  set,
  update,
} from "firebase/database"
import { Descendant, Node } from "slate"
import { EdgeInfo, PostMap, TextPost, TextPostWithoutId } from "./PostContext"
import natural, { PorterStemmerIt } from "natural"
import tokenizer from "wink-tokenizer"
//@ts-ignore
import { stemmer } from "stemmer"
import { getSlateEditorString } from "./Components/SlateEditorString"
import { getTextFromChildren } from "./Components/Search/Feed/Feed"
import { OpenAIApi, Configuration } from "openai"
import { signInWithPopup, User } from "firebase/auth"
import { backendWriter, usernamex } from "./Components/FullAppContainer"
import { time } from "console"
import { channel } from "diagnostics_channel"
import { barePostInfo, visionCommiteePosts } from "./ManualTransfers/VisionCommitteePosts"
import { thinkChatPosts } from "./ManualTransfers/ThinkChat"
import { getPosts } from "./ManualTransfers/DiscordBot"
import { text } from "stream/consumers"
import { auth, provider } from "./App"
import { getPlaceholder } from "./Components/EditorContainer"
// import sgMail from "@sendgrid/mail"

class FirebaseWriter {
  databaseRef: any
  personId: string
  personEmail: string
  personName: string
  todaysPrompt?: string
  constructor(
    databaseRef: any,
    personId: string,
    personEmail: string,
    personName: string,
    prompt?: string
  ) {
    this.databaseRef = databaseRef
    this.personId = personId
    this.personEmail = personEmail
    this.personName = personName
    this.todaysPrompt = prompt
  }
  setName(name: string) {
    this.personName = name
    // this.personId = name
  }
  setPersonId(id: string) {
    this.personId = id
  }
  ensureSignedIn(condition: boolean, callback: Function) {
    if (condition) return callback()
    else
      signInWithPopup(auth, provider).then((e) => {
        const { email, displayName, uid } = e.user
        if (email) this.personEmail = email
        this.personName = displayName ?? email ?? "anon"

        if (this.personName)
          window.alert("Good to have you here, " + this.personName.split(" ")[0] + ".")
        if (uid) {
          this.personId = uid
          return callback()
        }
      })
    return
  }

  addPostInternal(post: TextPostWithoutId, id?: string): addPostInternalResult | undefined {
    const postListRef = child(this.databaseRef, "nodes")
    const newPostRef = id ? child(postListRef, id) : push(postListRef)
    const newId = newPostRef.key
    if (newId) {
      //add the embedding in there
      const textWithoutBreaks =
        // post.text
        // post.text
        post.text
          .split("\n")
          .filter((e) => e)
          .join("\n")

      const textForEmbedding = `${post.prompt ? post.prompt.replace("?", ".") + " " : ""}${
        post.text
      }`
        .split("\n")
        .filter((e) => e)
        .join("\n")
      const postWithId: TextPost = {
        ...post,
        id: newId,
        text: textWithoutBreaks,
      }
      const addPostPromise = set(newPostRef, postWithId)

      if (!newPostRef.key) return undefined
      //Add to posts

      //Add words to dictionary
      //Get all words
      //text is the source of truth for string
      this.addToDict(textWithoutBreaks, newPostRef.key)

      //openai
      addPostPromise.then((e) => this.ensureEmbeddings(textForEmbedding, post.authorId, newPostRef))
      return { postWithId, newPostRef, addPostPromise }
    }
  }
  addToDict(text: string, postId: string) {
    //Add words to dictionary
    //Get all words
    //text is the source of truth for string
    const cleanedWords = tokenizeSentence(text)
    const updates = cleanedWords.reduce(
      (obj: { [wordpost: string]: boolean }, nextWord: string) => {
        obj[nextWord + "/" + postId] = true
        return obj
      },
      {}
    )
    const wordsRef = child(this.databaseRef, "dictionary")
    update(wordsRef, updates)
  }
  addPost(
    children: Descendant[],
    text: string
  ): { postWithId?: TextPost; addPostPromise?: Promise<any> } {
    const personId: string = this.personId
    if (!personId) this.ensureSignedIn(false, () => this.addPost(children, text))
    const textWithoutBreaks = text
      .split("\n")
      .filter((e) => e)
      .join("\n")
    const newPost: TextPost = makePost(
      personId,
      this.personName,
      backendWriter.personEmail,
      textWithoutBreaks,
      Date.now(),
      undefined,
      children
    ) as TextPost
    const result = this.addPostInternal(newPost)
    if (result) return { postWithId: result.postWithId, addPostPromise: result.addPostPromise }
    else return {}
  }
  batchPost(authorNameSuffix = " (discord)") {
    //need to remove slie eventually
    const x = getPosts()

    const postsToAdd = x.map((e) => ({
      ...e,
      text: e.text
        .split("\n")
        .filter((e) => e.length > 0 && e !== " ")
        .join("\n"),
    }))
    const texts = postsToAdd.map((e) => e.text)

    // thinkChatPosts.slice(0, 50)

    //now, add all of them, record each of their ids, then do embeddings for each
    const postListRef = child(this.databaseRef, "nodes")
    const idMap = postsToAdd.map((e) => push(postListRef).key)

    console.assert(idMap.filter((e) => e).length === idMap.length)

    //add em all
    const updatesWithoutEmbeddings: TextPost[] = postsToAdd
      .map((nextPost: barePostInfo, index: number) => {
        const postId = idMap[index]
        if (postId) {
          const post = makePost(
            "davey-batch",
            nextPost.name + authorNameSuffix,
            "davey@plexusnotes.com",
            nextPost.text + (nextPost.channel ? "  #" + nextPost.channel : ""),
            nextPost.timestamp,
            postId
          ) as TextPost
          return post
        }
        return undefined
      }, {})
      .filter((e) => e) as TextPost[]

    //get batch embeddings
    //query embeddings
    const queryEmbeddings = getOpenAiQueryEmbedding(texts, this.personId)
    let updatesArr: TextPost[] = updatesWithoutEmbeddings
    queryEmbeddings.then((e) => {
      const queryVectors = e.data.data.map((e: any) => e?.embedding)
      updatesArr = updatesArr.map((update, i) => {
        if (queryVectors[i]) return { ...update, openaiQueryEmbedding: queryVectors[i] }
        else return update
      })

      //then do the document embeddings
      const docEmbeddigs = getOpenAiDocEmbedding(texts, this.personId)
      docEmbeddigs.then((e) => {
        const docVectors = e.data.data.map((e: any) => e?.embedding)
        updatesArr = updatesArr.map((update, i) => {
          if (docVectors[i]) return { ...update, openaiEmbedding: docVectors[i] }
          else return update
        })
        const updatesMap = updatesArr.reduce(
          (updatesObj: { [id: string]: TextPost }, nextUpdate: TextPost) => {
            return { ...updatesObj, [nextUpdate.id]: nextUpdate }
          },
          {}
        )
        const postsRef = child(this.databaseRef, "nodes")
        update(postsRef, updatesMap).catch((e) => console.warn(e))
      })
    })

    // postsToAdd.forEach(({ name, timestamp, text, channel }, i) => {
    //   const post = makePost(
    //     "davey-batch",
    //     name,
    //     // + " (discord)",
    //     text + (channel ? "  #" + channel : ""),
    //     timestamp
    //   )
    //   this.addPostInternal(post)
    //   // window.alert("added " + name + "'s post")
    // })
  }
  sleep(milliseconds: number) {
    const date = Date.now()
    let currentDate = null
    do {
      currentDate = Date.now()
    } while (currentDate - date < milliseconds)
  }
  deleteAll() {
    remove(this.databaseRef)
  }
  ensureEmbeddings(text: string, authId: string, newPostRef: DatabaseReference) {
    //openai
    const embedding = getOpenAiDocEmbedding(text, this.personId ?? usernamex)
    //takes a second, but that's okay I think
    embedding
      //vector value
      .then((val) => {
        console.log({ val })
        //Here, try writing the value to Firebase. See how it does.
        //@ts-ignore
        const vector = val.data.data ? val.data.data[0].embedding : undefined
        if (vector) {
          update(newPostRef, { openaiEmbedding: vector })
        }
      })
      .catch((e) => console.warn(e))
      .finally(() => console.log("finished embedding retrieval process"))

    const queryEmbedding = getOpenAiQueryEmbedding(text, this.personId ?? usernamex)
    queryEmbedding
      .then((val) => {
        const vector = val.data.data ? val.data.data[0].embedding : undefined
        if (vector) {
          update(newPostRef, { openaiQueryEmbedding: vector })
        }
        console.log("embedding complete for ", text.slice(0, 15))
      })
      .catch((e) => console.warn(e))
      .finally(() => console.log("finished query embedding retrieval process"))
  }

  addLink(id1: string, id2: string, edgeInfo: EdgeInfo, anti: boolean = false) {
    //send an email test

    const linkKeyName = anti ? "antiLinks" : "links"
    //1
    const postListRef = child(this.databaseRef, "nodes")
    const newLinkRef = child(postListRef, id1 + "/" + linkKeyName + "/" + id2)
    set(newLinkRef, edgeInfo)
      .then(() => console.log("success link1"))
      .catch((e) => console.warn(e))

    //set timestamp
    if (!anti) {
      const firstNodeTimestamp = child(postListRef, id1 + "/receivedLinkTimestamp")
      set(firstNodeTimestamp, Date.now())
      const secondNodeTimestamp = child(postListRef, id2 + "/receivedLinkTimestamp")
      set(secondNodeTimestamp, Date.now())
    }

    //other way
    const secondLinkRef = child(postListRef, id2 + "/" + linkKeyName + "/" + id1)
    set(secondLinkRef, edgeInfo)
      .then(() => console.log("success link2"))
      .catch((e) => console.warn(e))
  }

  deleteLink(id1: string, id2: string, anti: boolean = false) {
    const linkKeyName = anti ? "antiLinks" : "links"

    const postListRef = child(this.databaseRef, "nodes")
    const newLinkRef = child(postListRef, id1 + "/" + linkKeyName + "/" + id2)
    set(newLinkRef, null)
      .then(() => console.log("success link1"))
      .catch((e) => console.warn(e))

    //other way
    const secondLinkRef = child(postListRef, id2 + "/" + linkKeyName + "/" + id1)
    set(secondLinkRef, null)
      .then(() => console.log("success link2"))
      .catch((e) => console.warn(e))
  }
  deletePost(post: TextPost) {
    //delete from post list
    const postListRef = child(this.databaseRef, "nodes")
    const newPostRef = child(postListRef, post.id)
    set(newPostRef, null)

    //delete links
    const links = post.links ? Object.keys(post.links) : []
    links.forEach((linkId) => {
      this.deleteLink(linkId, post.id)
    })

    //delete each word from the dictionary
    const updates: { [word: string]: null } = {}
    if (post.slateValue) {
      const words = tokenizeSentence(getTextFromChildren(post.slateValue))
      words.forEach((word) => {
        //delete from dictionary
        updates[`${word}/${post.id}`] = null
      })
    }
    const dictionaryRef = child(this.databaseRef, "dictionary")
    update(dictionaryRef, updates)
  }

  correctAuthorIds(posts: PostMap) {
    const updates: { [location: string]: {} } = {}
    if (!this.personName) return
    Object.values(posts)
      .filter((post) => post.authorId === this.personName)
      .forEach((post) => {
        //doesn't overwrite old authorId (ID), just new one.
        updates[post.id + "/authorID"] = this.personId
        updates[post.id + "/authorName"] = this.personName
      })
    const postsRef = child(this.databaseRef, "nodes")
    update(postsRef, updates)
  }
}
export default FirebaseWriter

//janky version for now
export const tokenizeSentence = (sentence: string): string[] => {
  var myTokenizer = new tokenizer()
  const result = myTokenizer
    .tokenize(sentence)
    .filter((token) => token.tag === "word" && !token.value.includes("'"))
    .map(({ value }) => value)
    .map((word) => stemmer(word))
  return result
}
export const cleanPhrase = (phrase: string): string => {
  const newPhrase = phrase.replace("'s", "").replace("’s", "")
  return tokenizeSentence(newPhrase).reduce((text, nextWord) => text + nextWord, "")
}
export const cleanWord = (word: string) => {
  return stemmer(word)
}

async function query(data: any) {
  const response = await fetch(
    "https://api-inference.huggingface.co/models/sentence-transformers/all-MiniLM-L6-v2",
    {
      headers: { Authorization: "Bearer xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx" },
      method: "POST",
      body: JSON.stringify(data),
    }
  )
  const result = await response.json()
  return result
}

export function getOpenAiTextEmbedding(text: string): Promise<any> {
  //openai
  const configuration = new Configuration({
    apiKey: "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",
  })
  const openai = new OpenAIApi(configuration)
  const response = openai.createEmbedding({
    model: "text-similarity-ada-001",
    input: text.slice(0, 80), //standardize to forty char
  })
  return response
}
export function getOpenAiQueryEmbedding(text: string | string[], personId: string): Promise<any> {
  //openai
  const textArr = (typeof text === "string" ? [text] : text).map((e) => e.replaceAll("\n", " "))
  const configuration = new Configuration({
    apiKey: "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",
  })
  const openai = new OpenAIApi(configuration)
  const response = openai.createEmbedding({
    model: "text-search-ada-query-001",
    input: textArr,
    user: personId,
  })
  return response
}
export function getOpenAiDocEmbedding(text: string | string[], personId: string): Promise<any> {
  //openai
  const configuration = new Configuration({
    apiKey: "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",
  })
  const openai = new OpenAIApi(configuration)
  const textArr = (typeof text === "string" ? [text] : text).map((e) => e.replaceAll("\n", " "))

  const response = openai.createEmbedding({
    model: "text-search-ada-doc-001",
    input: textArr,
    user: personId,
  })
  return response
}

export const makePost = (
  authorID: string,
  authorName: string,
  authorEmail: string,
  text: string,
  timestamp: number,
  id?: string,
  providedChildren?: Descendant[],
  prompt?: string
): TextPostWithoutId | TextPost => {
  const children: Descendant[] =
    providedChildren ??
    text.split("\n").map((e) => ({ type: "paragraph", children: [{ type: "text", text: e }] }))
  const post: TextPostWithoutId | TextPost = {
    authorID,
    authorId: authorID,
    authorName,
    slateValue: children,
    text,
    timestamp,
    id,
    authorEmail,
  }
  if (prompt) post.prompt = prompt
  return post
}

type addPostInternalResult = {
  postWithId: TextPost
  newPostRef: DatabaseReference
  addPostPromise: Promise<any>
}

function sendEmail() {
  // sgMail.setApiKey(process.env.SENDGRID_API_KEY as string)
  // const msg = {
  //   to: "test@example.com", // Change to your recipient
  //   from: "test@example.com", // Change to your verified sender
  //   subject: "Sending with SendGrid is Fun",
  //   text: "and easy to do anywhere, even with Node.js",
  //   html: "<strong>and easy to do anywhere, even with Node.js</strong>",
  // }
  // sgMail
  //   .send(msg)
  //   .then(() => {
  //     console.log("Email sent")
  //   })
  //   .catch((error: any) => {
  //     console.error(error)
  //   })
}
