import React, { useContext } from "react"
import { useParams } from "react-router-dom"
import AuthContext from "../../AuthContext"
import { EdgeKind, TextPost } from "../../PostContext"
import sendThanksEmail from "../../SendEmail"
import { backendWriter, usernamex } from "../FullAppContainer"
import FeedItem from "../Search/Feed/FeedItem/FeedItem"

const SuggestedThought = ({
  post,
  focusedThought,
}: {
  post: TextPost
  focusedThought?: TextPost
}) => {
  const { thoughtId } = useParams()
  const { person } = useContext(AuthContext)
  return (
    <div>
      <FeedItem post={post} originStory="suggested" />
      <div className="row" style={{ justifyContent: "center" }}>
        <div
          className={"pin-button unlink"}
          title={"disconnect this thought and the title-thought"}
          onClick={(e) => {
            e.stopPropagation()
            if (thoughtId && person?.uid) {
              backendWriter.addLink(
                thoughtId,
                post.id,
                {
                  sourceId: thoughtId,
                  authorId: person?.uid,
                  edgeKind: EdgeKind.LINK,
                  timestamp: Date.now(),
                },
                true //anti edge
              )
            }
          }}
        >
          ❌
        </div>
        &nbsp; &nbsp; &nbsp;
        <div
          className={"pin-button"}
          //   title={(unlinkable ? "dis" : "") + "connect this thought and the title-thought"}
          onClick={(e) => {
            e.stopPropagation()

            if (thoughtId && person?.uid) {
              //if different author
              if (person.uid !== post.authorId) {
                sendThanksEmail(
                  undefined,
                  usernamex,
                  focusedThought?.text,
                  post.text,
                  post.authorEmail
                )
              }
              backendWriter.addLink(thoughtId, post.id, {
                sourceId: thoughtId,
                authorId: person?.uid,
                edgeKind: EdgeKind.LINK,
                timestamp: Date.now(),
              })
            }
          }}
        >
          ✅{/* ✓ */}
        </div>
      </div>
    </div>
  )
}

export default SuggestedThought
