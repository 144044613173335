import { debug } from "console"
import { Editor, Node } from "slate"

export const getSlateEditorString = (editor: Editor) => {
  const iterator = Node.texts(editor)
  let nextString = iterator.next()
  let result = ""
  while (!nextString.done) {
    result += nextString.value[0].text
    nextString = iterator.next()
    result += nextString.done ? "" : "\n"
  }

  return result
}
