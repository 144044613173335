import React, { Dispatch, SetStateAction } from "react"
import { Descendant } from "slate"

let PostContext = React.createContext<{
  posts: PostMap
  setPosts: Dispatch<SetStateAction<PostMap>>
  dictionary: WordMap
  setDictionary: Dispatch<SetStateAction<WordMap>>
  personalFilter: boolean
  setPersonalFilter: Dispatch<SetStateAction<boolean>>
  relevanceFilter: boolean //will serve as a boolean; changing it to true will trigger a search for all the items most relevant to the given text
  setRelevanceFilter: Dispatch<SetStateAction<boolean>>
  semanticallyRelatedPosts: TextPost[] | undefined
  setSemanticallyRelatedPosts: Dispatch<SetStateAction<TextPost[] | undefined>>
  loadingPosts: boolean
  setLoadingPosts: Dispatch<SetStateAction<boolean>>
  todaysPrompt?: string
}>({
  posts: {},
  setPosts: () => {},
  dictionary: {},
  setDictionary: () => {},
  personalFilter: true,
  setPersonalFilter: () => {},
  relevanceFilter: false,
  setRelevanceFilter: () => {},
  semanticallyRelatedPosts: [],
  setSemanticallyRelatedPosts: () => {},
  loadingPosts: false,
  setLoadingPosts: () => false,
  todaysPrompt: undefined,
})
export default PostContext

export interface WordMap {
  [strippedWord: string]: { [postId: string]: false }
}
export interface PostMap {
  [postId: string]: TextPost
}
export interface TextPostWithoutId {
  timestamp: number
  text: string
  id?: string

  //do a conversion process? annoying problem this one.
  authorId: string //currently, this is just what the person says they'd like to be called.
  //there's the possibility for problematic overlap. should either replace this attribute, or make a new attribute based on fb id
  //corrected person info, not yet implemented
  authorID?: string //the firebase version, not yet implemented for all of them.
  authorName?: string //what the person says they'd like to be called, otherwise, their display name
  authorEmail: string
  //
  slateValue?: Descendant[]
  links?: { [postId: string]: EdgeInfo }
  antiLinks?: { [postId: string]: EdgeInfo }
  openaiEmbedding?: number[] //1024 spots
  openaiQueryEmbedding?: number[] //1024 spots, used as the query
  receivedLinkTimestamp?: number
  prompt?: string
}

export interface TextPost extends TextPostWithoutId {
  id: string
}
export interface EdgeInfoWithoutAuthor {
  sourceId: string
  edgeLabel?: string //will be the query string, by default
  pinnerIds?: { [authorId: string]: number } //number is timestamp
  edgeKind?: EdgeKind //if not provided, assume it's a link
  timestamp?: number
}
export interface EdgeInfo extends EdgeInfoWithoutAuthor {
  authorId: string
}
export enum EdgeKind {
  "LINK",
  "REPLY",
}
