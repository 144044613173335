import React from "react"
//Uzma's proposed stack styling component
const stackHeight = 10
const UzmaStack = ({ num }: { num: number }) => {
  return (
    <div style={{ marginBottom: "0em", marginTop: "2px" }}>
      {makeArr(num).map((e, i) => (
        <hr
          style={{
            width: String(100 - (i + 1) * 2) + "%",
            height: "2px",
            marginBottom: "2px",
            marginTop: "4px",
            border: "none",
            background: "black",
          }}
        ></hr>
      ))}
    </div>
  )
}

export function makeArr(num: number, value?: number) {
  let arr = []
  while (num > 0) {
    arr.push(typeof value === "undefined" ? num : value)
    num--
  }
  return arr
}

export default UzmaStack
